import Constants from 'expo-constants';

export const API_GATEWAY_BASE_URL: string =
  Constants.expoConfig?.extra?.API_GATEWAY_BASE_URL;

export const MAPBOX_ACCESS_TOKEN: string =
  Constants.expoConfig?.extra?.MAPBOX_ACCESS_TOKEN;

export const COGNITO_REGION: string =
  Constants.expoConfig?.extra?.COGNITO_REGION;

export const COGNITO_USER_POOL_ID: string =
  Constants.expoConfig?.extra?.COGNITO_USER_POOL_ID;

export const COGNITO_USER_POOL_WEB_CLIENT_ID: string =
  Constants.expoConfig?.extra?.COGNITO_USER_POOL_WEB_CLIENT_ID;

export const CONFIG_ENGINE_BASE_URL: string =
  Constants.expoConfig?.extra?.CONFIG_ENGINE_BASE_URL;

export const ERROR_LOGGER = {
  dsn: Constants.expoConfig?.extra?.SENTRY_DSN as string,
};
export const ENVIRONMENT: string = Constants.expoConfig?.extra?.ENVIRONMENT;

export const SENTRY_ENVIRONMENT: string =
  Constants.expoConfig?.extra?.SENTRY_ENVIRONMENT;

export const AMPLI_ENVIRONMENT: string =
  Constants.expoConfig?.extra?.AMPLI_ENVIRONMENT;

export const PHARMACY_ID: string = Constants.expoConfig?.extra?.PHARMACY_ID;

export const PHARMACY_NAME: string = Constants.expoConfig?.extra?.PHARMACY_NAME;

export const USER_API_BASE_URL: string =
  Constants.expoConfig?.extra?.USER_API_BASE_URL;

export const PATIENT_API_BASE_URL: string =
  Constants.expoConfig?.extra?.PATIENT_API_BASE_URL;

export const PRESCRIPTION_API_BASE_URL: string =
  Constants.expoConfig?.extra?.PRESCRIPTION_API_BASE_URL;

export const ORDER_API_BASE_URL: string =
  Constants.expoConfig?.extra?.ORDER_API_BASE_URL;

export const PHARMACY_API_BASE_URL: string =
  Constants.expoConfig?.extra?.PHARMACY_API_BASE_URL;

export const FORMS_API_BASE_URL: string =
  Constants.expoConfig?.extra?.FORMS_API_BASE_URL;

export const STATIC_DEV_LUMISTRY: string =
  Constants.expoConfig?.extra?.STATIC_DEV_LUMISTRY;

export const VUCA_API_BASE_URL: string =
  Constants.expoConfig?.extra?.VUCA_API_BASE_URL;

export const APPOINTMENT_API_BASE_URL: string =
  Constants.expoConfig?.extra?.APPOINTMENT_API_BASE_URL;

export const UNIFIED_COMMS_SERVICE_BASE_URL: string =
  Constants.expoConfig?.extra?.UNIFIED_COMMS_SERVICE_BASE_URL;

export const INBOX_SOCKET_URL: string =
  Constants.expoConfig?.extra?.INBOX_SOCKET_URL;

export const GOOGLE_WEB_CLIENT_ID: string =
  Constants.expoConfig?.extra?.GOOGLE_WEB_CLIENT_ID;
export const GOOGLE_EXPO_CLIENT_ID: string =
  Constants.expoConfig?.extra?.GOOGLE_EXPO_CLIENT_ID;
export const GOOGLE_IOS_CLIENT_ID: string =
  Constants.expoConfig?.extra?.GOOGLE_IOS_CLIENT_ID;
export const GOOGLE_ANDROID_CLIENT_ID: string =
  Constants.expoConfig?.extra?.GOOGLE_ANDROID_CLIENT_ID;

export const RETURN_URL_KEY = 'returnUrl';

export const FILE_STORAGE_SERVICE_BASE_URL: string =
  Constants.expoConfig?.extra?.FILE_STORAGE_SERVICE_BASE_URL;

export const PROXY_APP_BASE_URL: string =
  Constants.expoConfig?.extra?.PROXY_APP_BASE_URL;

export const APP_CONFIG_API_BASE_URL: string =
  Constants.expoConfig?.extra?.APP_CONFIG_API_BASE_URL;

export const SCHEME: string = Constants.expoConfig?.extra?.SCHEME;

export const APP_ENV: string = Constants.expoConfig?.extra?.APP_ENV;

export const DOMAIN: string = Constants.expoConfig?.extra?.DOMAIN;

export const GOOGLE_SSO_CLIENT_ID: string =
  Constants.expoConfig?.extra?.GOOGLE_SSO_CLIENT_ID;

export const COGNITO_GOOGLE_SSO_URL: string =
  Constants.expoConfig?.extra?.COGNITO_GOOGLE_SSO_URL;
