import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Text } from 'assets/components/text';
import { useForm } from 'assets/form';
import { makeStyles, useTheme } from 'assets/theme';
import { Button } from 'assets/components/button';
import { getText } from 'assets/localization/localization';
import { Form, ScreenContainer } from 'assets/layout';
import { View } from 'react-native';
import { PatientUnderCareForm } from './patient-actions';
import { usePatientUnderCareState } from './patient-store';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useAppStateStore } from '../../../store/app-store';
import { useUserState } from '../../../store/user-store';
import { PatientUnderCareStackParamList } from './PatientUnderCareNavigation';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { CheckboxField } from 'assets/components/checkbox';
import {
  TypeaheadWithTagsField,
  TypeaheadWithTagsFieldProps,
} from 'assets/components/typeahead-with-tags';
import refillService from '../../refill/refill-service';
import patientService from '../../../api/patient-service';
import {
  Gender,
  UpdatePatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';

export const AddMinorOrPetPatient: FunctionComponent<
  PropsWithChildren<AddMinorOrPetPatientProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const { status, error, data, patientRecord } = usePatientUnderCareState();
  const { user } = useUserState();
  const { pharmacyName } = useAppStateStore();

  const methods = useForm<PatientUnderCareForm>({
    defaultValues: {
      first_name: patientRecord?.first_name,
      last_name: patientRecord?.last_name,
      date_of_birth: patientRecord?.date_of_birth,
      relationship: data?.relationship,
      email: '',
      allergies: [],
      prefers_easy_open_bottle_caps: false,
      prefers_med_sync: '',
      patient_record_id: patientRecord?.id,
    },
  });

  const allergiesFieldProps: TypeaheadWithTagsFieldProps = {
    name: 'allergies',
    typeaheadWithTagsProps: {
      label: getText('allergies') + ' *',
      multiple: true,
      asyncOptions: async (searchTerm: string) => {
        if (!searchTerm) return Promise.resolve([]);
        return refillService.searchAllergies(searchTerm);
      },
      hintMessage: getText('your-allergies'),
      emptyValue: { text: 'No known allergies', value: 'no_allergies' },
      tags: refillService.topAllergiesHints,
    },
  };

  const medicalConditionsFieldProps: TypeaheadWithTagsFieldProps = {
    name: 'medical_conditions',
    typeaheadWithTagsProps: {
      multiple: true,
      asyncOptions: async (searchTerm: string) => {
        if (!searchTerm) return Promise.resolve([]);
        return refillService.searchMedicalConditions(searchTerm);
      },
      hintMessage: 'Medical conditions',
      tags: refillService.topMedicalConditionsHints,
    },
  };

  const handleSubmit = async () => {
    const patientMedicalInfos: UpdatePatientRecordDto = {
      gender: methods.getValues().gender,
      allergies: methods
        .getValues()
        .allergies?.map((v) => v.value)
        .filter((v) => v !== 'no_allergies'),
      medical_conditions: methods
        .getValues()
        .medical_conditions?.map((v) => v.value),
      prefers_easy_open_bottle_caps:
        methods.getValues().prefers_easy_open_bottle_caps,
      prefers_med_sync:
        methods.getValues().prefers_med_sync === 'yes' ? true : false,
    };
    const patientRecordId = methods.getValues().patient_record_id;
    if (!patientRecordId) throw new Error('No patient id');
    await patientService.updatePatientRecord(
      patientRecordId,
      patientMedicalInfos,
    );
    navigation.navigate('add-minor-or-pet-request-submitted');
  };

  return (
    <ScreenContainer>
      <View
        style={{
          marginTop: theme.getSpacing(2),
        }}
      >
        <View style={styles.requestTextContainer}>
          <Text style={styles.pucContainer}>
            {`${methods.getValues('first_name')} ${methods.getValues(
              'last_name',
            )}`.trim()}
          </Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.dobStyle}>
              {methods.getValues('date_of_birth')}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          marginTop: theme.getSpacing(2),
        }}
      >
        <Form methods={methods}>
          <Form.Alert title={error?.message} intent="error" visible={!!error} />
          <Form.Row>
            <Form.Column style={{ marginTop: theme.getSpacing(1) }}>
              <Text> {getText('gender')}</Text>
              <RadioButtonGroupField
                name="gender"
                testID={''}
                values={[
                  {
                    text: getText('male'),
                    value: Gender.Male,
                  },
                  {
                    text: getText('female'),
                    value: Gender.Female,
                  },
                ]}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TypeaheadWithTagsField {...allergiesFieldProps} />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TypeaheadWithTagsField {...medicalConditionsFieldProps} />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <View style={styles.headerTitle}>
                <Text style={styles.headerTitleText}>
                  {getText('more-information')}
                </Text>
              </View>
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <CheckboxField
                label={getText('easy-open-bottle-caps')}
                name="prefers_easy_open_bottle_caps"
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column style={{ marginTop: theme.getSpacing(1) }}>
              <Text>{getText('monthly-prescriptions')}</Text>
              <RadioButtonGroupField
                name="prefers_med_sync"
                testID={'monthly-prescriptions-id'}
                values={[
                  {
                    text: getText('yes'),
                    value: 'yes',
                  },
                  {
                    text: getText('no'),
                    value: 'no',
                  },
                ]}
              />
            </Form.Column>
          </Form.Row>
          <Form.Actions>
            <Button
              onPress={methods.handleSubmit(handleSubmit)}
              hierarchy="primary"
              loading={status === 'loading'}
              logger={{ id: 'add-minor-patient-details-next-button' }}
            >
              {getText('next')}
            </Button>
          </Form.Actions>
        </Form>
      </View>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.getSpacing(2),
    alignSelf: 'center',
    backgroundColor: theme.palette.gray[100],
    width: '100%',
  },
  pucContainer: {
    fontWeight: '600',
    fontSize: 24,
    lineHeight: 26,
    color: theme.palette.gray[700],
  },
  dobStyle: {
    color: theme.palette.gray[700],
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  headerTitle: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(1),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  headerTitleText: {
    marginBottom: theme.getSpacing(1),
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '600',
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 18,
    marginTop: theme.getSpacing(3),
    lineHeight: 28,
  },
  text: {
    ...theme.fonts.medium,
    color: theme.palette.gray[700],
    fontWeight: '400',
    fontSize: 14,
    marginTop: theme.getSpacing(2),
    paddingStart: theme.getSpacing(1),
    paddingEnd: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
    lineHeight: 20,
  },
  requestTextContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  pressableTextRevoke: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
  },
  textContainer: { flexDirection: 'row', alignItems: 'center' },
  leftSpacing: { marginLeft: theme.getSpacing(1) },
}));

interface AddMinorOrPetPatientProps
  extends NativeStackScreenProps<
    PatientUnderCareStackParamList,
    'add-minor-or-pet-patient'
  > {}
