import { View, Text } from 'react-native';
import { useMessagesState } from './messages-store';
import { makeStyles } from 'assets/theme';
import {
  buildMessageList,
  setCounts,
  setViewedConversations,
} from './messages-actions';
import { useEffect } from 'react';
import { useUserState } from '../../store/user-store';
import { useSockets } from '../messages/useSockets';

export const MessageCount = (): JSX.Element | null => {
  useSockets();
  const { count, rawConversations, viewedConversations } = useMessagesState();
  const { user } = useUserState();

  useEffect(() => {
    void (async () => {
      await buildMessageList();
    })();
  }, [user?.preferredPharmacyLprId]);

  useEffect(() => {
    const newViewedConversations = rawConversations
      .filter((conversation) => conversation.patient_viewed_all_messages)
      .map((conversation) => conversation.conversation_id);

    setViewedConversations(newViewedConversations);
  }, [rawConversations]);

  useEffect(() => {
    (async () => {
      const count: number =
        rawConversations.length - viewedConversations.length;
      setCounts({ unread: count });
    })();
  }, [rawConversations, viewedConversations]);

  const styles = useStyles();

  return count.unread ? (
    <View style={styles.container}>
      <Text style={styles.text}>{count.unread}</Text>
    </View>
  ) : null;
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: theme.getSpacing(1),
    borderRadius: 50,
    backgroundColor: theme.palette.primary[600],
    width: 25,
    height: 25,
    alignItems: 'center',
    justifyContent: 'center',
  },

  text: {
    color: theme.palette.white,
  },
}));
