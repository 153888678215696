import { ScreenContainer } from 'assets/layout';
import { makeStyles, useTheme } from 'assets/theme';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { Platform, TouchableOpacity, View } from 'react-native';
import { getText } from 'assets/localization/localization';
import { Icon } from 'assets/components/icon';
import { ArrowLeftCircleIcon, PillsIcon, ShoppingCartIcon } from 'assets/icons';
import { PrescriptionCard } from '../../components/prescription-card';
import { Text } from 'assets/components/text';
import { Form } from 'assets/layout';
import { Divider } from 'react-native-paper';
import { Button } from 'assets/components/button';
import { DropdownSelect } from 'assets/components/dropdown-select';
import { useForm } from 'assets/form';
import { useRefillMedicationsStore } from '../refill/refill-store';
import refillService from '../refill/refill-service';
import { OrderMedicationsHandler } from './order-medications-alert/OrderMedicationsAlertProps';
import patientService from '../../api/patient-service';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';
import { UnableToSubmitHandler } from '../refill/unable-to-submit/UnableToSubmitProps';
import { UnableToSubmit } from '../refill/unable-to-submit/UnableToSubmit';
import { ampli } from '../../ampli';
import { useUserState } from '../../store/user-store';
import { EmptyStatePage } from '../../components/empty-state-page/EmptyStatePage';
import { OrderMedicationsAlert } from './order-medications-alert/OrderMedicationAlert';
import { useAddPrescriptionStore } from '../find-prescription-flow/add-prescription-store';
import { StackHeaderProps } from '@react-navigation/stack';
import { notImplementedAlert } from 'assets/utils/alert';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { MedicationsEmptyStateSmall } from './medications-empty-state/MedicationsEmptyStateSmall';
import { ErrorOccurredPage } from '../../components/error-occurred/ErrorOccurred';
import {
  LocationPatientRecordDto,
  MinLocationPatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { getPatientRecord } from '../find-prescription-flow/add-prescription-actions';

export type PatientRecordItem = {
  patientRecordId: string;
  fullName: string;
  location_patient_record: MinLocationPatientRecordDto[];
};

export const Medications: FunctionComponent<
  PropsWithChildren<MedicationsProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const { user } = useUserState();
  const {
    isFirstTimeRegister,
    allMedications,
    selectedPatient,
    updateMedicationsData,
  } = useRefillMedicationsStore();
  const [patientRecordItems, setSelectedDependents] = useState<
    PatientRecordItem[]
  >([]);
  const orderAlertRef = React.useRef<OrderMedicationsHandler>(null);
  const unableToSubmitRef = React.useRef<UnableToSubmitHandler>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errorOccurred, setErrorOccurred] = React.useState<boolean>(false);
  const [overTheCounterMeds, setOverTheCounterMeds] = useState([]);

  const methods = useForm({
    defaultValues: {
      selectedPatientRecordId: '',
    },
  });
  const selectedPatientRecordId = methods.watch('selectedPatientRecordId');

  const loadPatientUnderCare = async (userId: string) => {
    if (!user?.patientRecordId) return alert('No Patient Record Found');

    try {
      const patientsUnderCare =
        await patientService.recordUnderCareFindByPatientUser(userId);
      const patientRecord = await patientService.findPatientRecord(
        user.patientRecordId,
      );

      const selectedDependentsResponse: PatientRecordItem[] = [
        {
          patientRecordId: user.patientRecordId,
          fullName: 'Myself',
          location_patient_record: patientRecord.location_patient_records,
        },
        ...patientsUnderCare.map((puc) => ({
          patientRecordId: puc.record_under_care.id,
          fullName: `${puc.record_under_care.first_name} ${puc.record_under_care.last_name}`,
          location_patient_record: puc.location_patient_records,
        })),
      ];

      setSelectedDependents(selectedDependentsResponse);
    } catch (error) {
      alert('Not supported error');
    }
  };

  const getLocationPatientRecordFromPUC = (): string | undefined => {
    const locationId = user?.preferredPharmacyLocationId;

    const pucRecord = patientRecordItems.find(
      (puc) => puc.patientRecordId === selectedPatientRecordId,
    );
    const lprRecord = pucRecord?.location_patient_record.find(
      (lpc) => lpc.location_id === locationId,
    );

    return lprRecord?.id;
  };

  // Set defaults for patient if first time
  useEffect(() => {
    if (patientRecordItems.length) {
      const selectedPatientRecordItem = patientRecordItems.find(
        (pri) => pri.patientRecordId === selectedPatientRecordId,
      );

      // set default if no value is selected and found
      if (!selectedPatientRecordItem) {
        methods.setValue(
          'selectedPatientRecordId',
          patientRecordItems[0].patientRecordId,
        );
      }
    } else {
      methods.setValue('selectedPatientRecordId', '');
    }
  }, [patientRecordItems]);

  // Load Patient dropdown list
  useEffect(() => {
    if (user?.id) void loadPatientUnderCare(user.id);
  }, [user?.id]);

  // Load Prescriptions based on selected Location/Patient
  useEffect(() => {
    void (async () => {
      if (
        !updateMedicationsData ||
        !user?.preferredPharmacyLocationId ||
        !selectedPatientRecordId
      )
        return;

      setIsLoading(true);

      const selectedLPRId = getLocationPatientRecordFromPUC();

      let prescriptions: PrescriptionDto[] = [];
      let lprRecord: LocationPatientRecordDto | undefined;
      let isFirstTimeRegister = false;

      if (selectedLPRId) {
        lprRecord = await patientService.findLocationPatientRecord(
          user.preferredPharmacyLocationId,
          selectedLPRId,
        );

        isFirstTimeRegister = !(await refillService.getLastRefill(
          user.preferredPharmacyLocationId,
          selectedLPRId,
        ));

        prescriptions = await refillService.getPatientPrescriptions(
          user.preferredPharmacyLocationId,
          selectedLPRId,
        );
      }

      updateMedicationsData({
        showRefillMedications: false,
        isFirstTimeRegister: isFirstTimeRegister,
        selectedPatient: lprRecord,
        allMedications: prescriptions
          .sort((a, b) => {
            if (!a.next_refill_date) return 1;
            if (!b.next_refill_date) return -1;
            return a.next_refill_date > b.next_refill_date ? 1 : -1;
          })
          .map((prx) => ({
            ...prx,
            checked: false,
          })),
      });
      setIsLoading(false);
    })();
  }, [selectedPatientRecordId, user?.preferredPharmacyLocationId]);

  const handleSelectMedication = (
    medication: PrescriptionDto,
    checked: boolean,
  ) => {
    if (!updateMedicationsData) return;

    updateMedicationsData({
      allMedications: allMedications?.map((x) => ({
        ...x,
        checked: x.rx_number === medication.rx_number ? checked : x.checked,
      })),
    });
  };

  const handleShowUnableToSubmit = () => {
    unableToSubmitRef.current?.show();
  };

  const handleUnableToSubmit = async (value: string) => {
    if (value === 'continue') {
      navigation.navigate('refill');
    }
    if (value === 'back') {
      unableToSubmitRef.current?.hide();
    }
  };

  const handleOrderRefill = async () => {
    if (
      !user?.preferredPharmacyLocationId ||
      !updateMedicationsData ||
      !allMedications ||
      !selectedPatient?.id
    ) {
      return alert('Missing Information');
    }

    const selectedMedications = allMedications.filter((x) => x.checked);

    // If no medication selected show alert modal
    if (selectedMedications.length === 0) {
      return orderAlertRef.current?.show();
    }

    const unableToRefillMedications =
      await refillService.checkPatientPrescriptions(
        selectedMedications,
        user.preferredPharmacyLocationId,
        selectedPatient.id,
      );

    if (unableToRefillMedications.length > 0) {
      updateMedicationsData({
        selectedLocationId: user.preferredPharmacyLocationId,
        unableToSubmitMedications: unableToRefillMedications,
      });
      if (!isFirstTimeRegister) {
        return handleShowUnableToSubmit();
      }
    }

    const selectedPatientRecord = await patientService.findPatientRecord(
      selectedPatientRecordId,
    );

    updateMedicationsData({
      selectedLocationId: user.preferredPharmacyLocationId,
      selectedMedications: selectedMedications,
      selectedPatientRecord: selectedPatientRecord,
    });

    navigation.navigate('refill');
  };

  const handleAddAnotherMed = async () => {
    const locationId = user?.preferredPharmacyLocationId;
    if (!locationId) return alert('No Preferred Location Selected');
    if (!selectedPatientRecordId) return alert('No Patient Selected');

    const isLocationIntegrated =
      await refillService.isLocationIntegrated(locationId);

    if (isLocationIntegrated) {
      useAddPrescriptionStore.setState({
        originated: 'medication',
        locationPatientRecordId: undefined,
      });
      // this will populate the useAddPrescriptionStore with data
      void getPatientRecord(selectedPatientRecordId);

      navigation.navigate('add-prescription', {
        screen: 'automatic-prescription-flow',
      });
    } else {
      // when the location is not integrated we check for lpr existence
      let locationPatientRecordId = getLocationPatientRecordFromPUC();

      if (!locationPatientRecordId) {
        const patientRecord =
          await patientService.patientRecordInitLocationPatientRecord(
            selectedPatientRecordId,
            { location_id: locationId },
          );
        locationPatientRecordId = patientRecord.location_patient_records.find(
          (lpr) => lpr.location_id === locationId,
        )?.id;

        void loadPatientUnderCare(user.id);
      }

      useAddPrescriptionStore.setState({
        originated: 'medication',
        locationPatientRecordId: locationPatientRecordId,
        prescriptions: allMedications || [],
      });

      navigation.navigate('add-prescription', {
        screen: 'manual-prescription-flow',
      });
    }
  };

  const handleAddAnotherOTCMed = async () => {
    navigation.navigate('add-prescription', {
      screen: 'manual-prescription-flow',
    });
  };

  const handleTransferMedicationPress = () => {
    notImplementedAlert();
  };

  const handleOnPressError = () => {
    navigation.navigate('app', { screen: 'home' });
  };

  return (
    <>
      <ScreenContainer>
        {errorOccurred ? (
          <ErrorOccurredPage onPress={handleOnPressError} />
        ) : (
          <>
            <View style={{ marginVertical: theme.getSpacing(1) }}>
              {patientRecordItems.length > 1 && (
                <Form methods={methods}>
                  <View>
                    <Text style={styles.helperText}>
                      {getText('viewing-medications-for')}
                    </Text>
                  </View>
                  <DropdownSelect
                    fieldName="selectedPatientRecordId"
                    options={patientRecordItems.map((item) => ({
                      label: item.fullName,
                      value: item.patientRecordId,
                    }))}
                  />
                </Form>
              )}
            </View>
            {isLoading ? (
              <View style={styles.loadingIndicator}>
                <LoadingIndicator />
              </View>
            ) : allMedications?.length === 0 ? (
              <EmptyStatePage
                title={getText('medications-empty-state-title')}
                description={getText('medications-empty-state-description')}
                buttonText={getText('add-meds')}
                icon={PillsIcon}
                onPress={handleAddAnotherMed}
              />
            ) : (
              <>
                <View style={{ marginTop: theme.getSpacing(1) }}>
                  <TouchableOpacity
                    style={styles.rowView}
                    onPress={handleTransferMedicationPress}
                  >
                    <Icon
                      icon={ArrowLeftCircleIcon}
                      color={theme.palette.primary[600]}
                    />
                    <Text style={styles.link}>{getText('transfer-meds')}</Text>
                  </TouchableOpacity>
                </View>

                <View style={{ marginVertical: theme.getSpacing(1) }}>
                  <View style={styles.rowSpaceBetween}>
                    <Text style={styles.titleText}>
                      {getText('prescription')}
                    </Text>
                    {allMedications && allMedications.length > 0 && (
                      <Text style={styles.link} onPress={handleAddAnotherMed}>
                        {getText('add-another')}
                      </Text>
                    )}
                  </View>

                  {allMedications && allMedications.length > 0 ? (
                    allMedications.map((medication) => (
                      <View key={medication.rx_number}>
                        <View style={{ marginBottom: theme.getSpacing(1) }}>
                          <Divider />
                        </View>
                        <PrescriptionCard
                          check={medication.checked}
                          selectable={true}
                          showRefillStatus={true}
                          showRxNumber={false}
                          showPatientInfo={false}
                          onPress={handleSelectMedication}
                          prescription={medication}
                          patient={selectedPatient}
                        />
                      </View>
                    ))
                  ) : (
                    <>
                      <View style={{ marginBottom: theme.getSpacing(1) }}>
                        <Divider />
                      </View>
                      <MedicationsEmptyStateSmall
                        firstParagraph={getText(
                          'prescriptions-empty-state-paragraph-one',
                        )}
                        secondParagraph={getText(
                          'prescriptions-empty-state-paragraph-two',
                        )}
                        buttonText={getText(
                          'prescriptions-empty-state-button-text',
                        )}
                        onPress={handleAddAnotherMed}
                      />
                    </>
                  )}
                </View>
                <View
                  style={{
                    display: 'none',
                    marginVertical: theme.getSpacing(1),
                  }}
                >
                  <View style={styles.rowSpaceBetween}>
                    <Text style={styles.titleText}>
                      {getText('over-the-counter')}
                    </Text>
                    {overTheCounterMeds && overTheCounterMeds.length > 0 && (
                      <Text
                        style={styles.link}
                        onPress={handleAddAnotherOTCMed}
                      >
                        {getText('add-another')}
                      </Text>
                    )}
                  </View>
                  <View style={{ marginBottom: theme.getSpacing(1) }}>
                    <Divider />
                  </View>
                  {overTheCounterMeds && overTheCounterMeds.length > 0 ? (
                    overTheCounterMeds.map((otc, index) => (
                      <View key={index}>
                        <PrescriptionCard
                          check={false}
                          selectable={true}
                          showRxNumber={false}
                          showPatientInfo={false}
                          onPress={handleSelectMedication}
                          prescription={otc}
                          patient={selectedPatient}
                        />
                      </View>
                    ))
                  ) : (
                    <MedicationsEmptyStateSmall
                      firstParagraph={getText('otc-empty-state-paragraph-one')}
                      secondParagraph={getText('otc-empty-state-paragraph-two')}
                      buttonText={getText('otc-empty-state-button-text')}
                      onPress={handleAddAnotherOTCMed}
                    />
                  )}
                </View>

                {orderAlertRef && <OrderMedicationsAlert ref={orderAlertRef} />}
                <UnableToSubmit
                  ref={unableToSubmitRef}
                  handleUnableToSubmit={handleUnableToSubmit}
                />
              </>
            )}
          </>
        )}
      </ScreenContainer>
      {!errorOccurred && !isLoading && allMedications?.length !== 0 && (
        <View style={styles.stickyButton}>
          <Button
            hierarchy="primary"
            testID={'order-button-id'}
            logger={{ id: 'order-button-id' }}
            icon={ShoppingCartIcon}
            onPress={handleOrderRefill}
          >
            {getText('order')}
          </Button>
        </View>
      )}
    </>
  );
};

export type MedicationsProps = StackHeaderProps;

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginTop: theme.getSpacing(3),
  },
  divider: {
    margin: theme.getSpacing(1),
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.getSpacing(2),
    gap: theme.getSpacing(0.5),
    backgroundColor: '#FEFBE8',
    border: '1px solid #BCC8CE',
    borderRadius: 6,
    marginVertical: theme.getSpacing(1),
  },
  rowView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.getSpacing(2),
    marginTop: theme.getSpacing(1),
  },
  stickyButton: {
    position: 'absolute',
    width: Platform.OS === 'web' ? 50 : 100,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    resizeMode: 'contain',
    right: Platform.OS === 'web' ? 550 : 30,
    bottom: 30,
    alignSelf: 'flex-end',
  },
  link: {
    textAlign: 'right',
    color: theme.palette.primary[600],
  },
  titleText: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[900],
  },
  loadingIndicator: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top: '45%',
    marginVertical: theme.getSpacing(2),
  },
  helperText: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
}));
