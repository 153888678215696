import { logError, logEvent } from 'assets/logging/logger';
import { useUserState } from '../../store/user-store';
import { useAppStateStore } from '../../store/app-store';
import { useLoginState } from './login-store';
import { getText } from 'assets/localization/localization';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StorageKeys } from '../../../enums/storage-keys';
import { User } from '@sentry/react-native';
import UsersServiceInstance from '../../api/users-service';
import {
  PatientUserDto,
  UserLogin,
  UserLoginResponse,
} from '@digitalpharmacist/users-service-client-axios';
import { AuthStackNavigationProp } from '../../navigation/AuthNavigation';
import { ampli } from '../../common/ampliPatient';
import { resetAddPrescriptionState } from '../find-prescription-flow/add-prescription-actions';
import usersService from '../../api/users-service';
import Constants from 'expo-constants';

export const login = async (
  values: UserLogin,
  navigation: AuthStackNavigationProp,
): Promise<void> => {
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  useLoginState.setState({ error: undefined, status: 'loading' });
  useLoginState.setState({ email: values.email });

  try {
    values.pharmacyId = pharmacyId;

    const userLoginResponse: UserLoginResponse =
      await UsersServiceInstance.logIn(values);
    if (!userLoginResponse.accessToken) {
      throw new Error(getText('email-or-password-incorrect'));
    }
    ampli.userLoggedIn({
      biometricAuthenticationMethod: 'none',
      loginMethod: 'email',
      loginStatus: 'Logged In',
      loginTime: new Date().toISOString(),
      productVersion: Constants.expoConfig?.version!,
    });

    await AsyncStorage.setItem(
      StorageKeys.AccessToken,
      userLoginResponse.accessToken,
    );

    await AsyncStorage.setItem(
      StorageKeys.RefreshToken,
      userLoginResponse.refreshToken,
    );

    useLoginState.setState({ status: 'success' });
    if (!userLoginResponse.patient_user) {
      throw new Error(getText('email-or-password-incorrect'));
    }
    const user: PatientUserDto = userLoginResponse.patient_user;
    if (user.id) {
      await AsyncStorage.setItem(StorageKeys.UserId, user.id);
      useUserState.setState({
        user: user,
      });
    } else {
      throw new Error(getText('email-or-password-incorrect'));
    }
  } catch (e) {
    ampli.userLoggedIn({
      biometricAuthenticationMethod: 'none',
      loginMethod: 'email',
      loginStatus: 'Log in Failed',
      loginTime: new Date().toISOString(),
      productVersion: Constants.expoConfig?.version!,
    });
    if (e === 'The requested resource was not found') {
      navigation.navigate('account-not-found');
    } else if (e === 'Forbidden') {
      navigation.navigate('login-attempts-exceeded');
    } else {
      useLoginState.setState({
        error: {
          message: e as string,
        },
        status: 'error',
      });
    }
  }
};

export const checkUserStatus = async (
  email: string,
  navigation: AuthStackNavigationProp,
  queryParams?: any,
): Promise<void> => {
  try {
    useLoginState.setState({ error: undefined, status: 'loading' });
    const pharmacyId = useAppStateStore.getState().pharmacyId;
    const data = await UsersServiceInstance.checkUser(email, pharmacyId);
    // TODO we need to handle one more case:
    //the case where user exists in  dp1
    if (!data.dp1 && !data.dp2) {
      navigation.navigate('register-confirmation', queryParams);
    } else if (!data.dp1 && data.dp2) {
      useLoginState.setState({ email: email });
      navigation.navigate('login', queryParams);
    } else if (data.dp1 && !data.dp2) {
      navigation.navigate('register-confirmation', queryParams);
    }
    useLoginState.setState({ status: 'success' });
  } catch (e) {
    useLoginState.setState({
      error: {
        message: e as string,
      },
      status: 'error',
    });
  }
};

export const logout = async (): Promise<void> => {
  try {
    void AsyncStorage.clear();
    useUserState.setState({
      user: undefined,
    });
    resetAddPrescriptionState();

    ampli.userLoggedOut({
      logoutMethod: 'logout',
      logoutTime: new Date().toISOString(),
    });
  } catch (error: any) {
    ampli.userLoggedOut({
      logoutMethod: 'logout failed',
      logoutTime: new Date().toISOString(),
    });
    logError(error);
  }
};

export const googleLogin = async (
  idToken: string,
  accessToken: string,
  navigation: AuthStackNavigationProp,
): Promise<void> => {
  useLoginState.setState({ error: undefined, status: 'loading' });
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  try {
    const response = await usersService.googleLogin({ idToken, accessToken });
    await AsyncStorage.setItem(StorageKeys.AccessToken, response.accessToken);
    //TODO: Add this back when backend starts returning refresh token for sso
    //await AsyncStorage.setItem(StorageKeys.RefreshToken, response.refreshToken);
    await AsyncStorage.setItem(StorageKeys.UserId, response.patient_user.id);

    useUserState.setState({
      user: response.patient_user,
    });
    useLoginState.setState({ status: 'success' });

    ampli.userLoggedIn({
      biometricAuthenticationMethod: 'none',
      loginMethod: 'Google',
      loginStatus: 'Logged In',
      loginTime: new Date().toISOString(),
      productVersion: Constants.expoConfig?.version!,
    });
  } catch (e) {
    if (e === 'The requested resource was not found') {
      navigation.navigate('account-not-found', { isSSO: true });
      useLoginState.setState({
        status: 'idle',
      });
    } else {
      useLoginState.setState({
        error: { message: getText('google-login-failed') },
        status: 'error',
      });
    }
    logEvent('google_login_failed', { error: e });
    ampli.userLoggedIn({
      biometricAuthenticationMethod: 'none',
      loginMethod: 'Google',
      loginStatus: 'Google login failed',
      loginTime: new Date().toISOString(),
      productVersion: Constants.expoConfig?.version!,
    });
  }
};

export interface LoginForm {
  email: string;
  password: string;
  pharmacyId: string;
}

export interface UserToken {
  accessToken: string;
  idToken: string;
  userInfo: User;
}
